<ng-content></ng-content>
<ul class="hm-chord-input__button-list">
  <li
    *ngFor="let n of [0,1,2,3,4,5,6]"
    class="hm-chord-input__button-list-item">
    <button
      hmButton
      [value]="n"
      (click)="onChordSelect($event)">
        {{ n | roman }}
    </button>
  </li>
</ul>
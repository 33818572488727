<form
  class="hm-key-change-form__current-key-form">
  <hm-title-bar class="hm-key-change-form__current-key-display">
      Key
      <div class="hm-key-change-form__current-key-centre">{{ currentGlobalKey.name }}{{ currentGlobalKey.quality }}</div>
  </hm-title-bar>
  
  <div class="hm-key-change-form__input-group">
    <div class="hm-key-change-form__input-group-item">
      <mat-form-field class="hm-key-change-form__key-name-select">
        <mat-label>Change Key</mat-label>
        <select 
          ngModel 
          name="keyNote" 
          (ngModelChange)="onKeyNoteChange($event)" 
          matNativeControl
          [value]="currentGlobalKey.name">
          <option 
            *ngFor="let note of keysDataNotes" 
            [value]="note">
            {{note}}
          </option>
        </select>
      </mat-form-field>
    </div>
    <div class="hm-key-change-form__input-group-item">
      <mat-button-toggle-group 
        value="maj"
        (change)="onQualityChange($event)"
        class="hm-key-change-form__maj-min-toggle">
        <mat-button-toggle
          value="maj">
          Maj
        </mat-button-toggle>
        <mat-button-toggle
          value="min">
          min
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</form>
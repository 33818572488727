<h2 mat-dialog-title>Choose a song name</h2>
<div mat-dialog-content>
  <!-- <p>Song Name</p> -->
  <mat-form-field>
    <input matInput name="songName" placeholder="Song Name" [(ngModel)]="data.songName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.songName" cdkFocusInitial>Save</button>
</div>
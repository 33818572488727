<div class="hm-hitme__chords-display-grid">
  <h2 *ngIf="songName">{{songName}}</h2>
  <hm-chord-display [displayChords]="chordsToDisplay">
  </hm-chord-display>

  <div 
    class="hm-hitme__intro-text"
    *ngIf="!chordsToDisplay?.length"
    @introText>
    <h1 class="hm-hitme__intro-heading">
      Hit me
    </h1>
    <p class="hm-hitme__intro-subheading">
      songwriting tool
    </p>

  </div>
</div>


<div class="hm-hitme__sticky-bottom">
  <div class="hm-hitme__input-control-grid">
    <hm-chord-input>
      <div 
        *ngIf="!chordsToDisplay?.length"
        class="hm-hitme__onboard"
        @popout>
        Enter some chord numbers to get started
      </div>
    </hm-chord-input>

    <button 
      hmButton
      class="hm-hitme__button" 
      (click)="onHitMe()"
      [disabled]="!chordsToDisplay?.length">
      Suggest
    </button>

    <button 
      hmButton
      class="hm-hitme__reset-button"
      (click)="onReset()"
      [disabled]="!chordsToDisplay?.length">
      Reset
    </button>

    <button 
      hmButton="primary"
      class="hm-hitme__save-button" 
      (click)="openSaveSongDialog()"
      [disabled]="!chordsToDisplay?.length">
      Save
    </button>

    
  </div>
</div>